import { createStore } from 'vuex'
import { router } from '@inertiajs/vue3'
// import { jsonToFormData } from '@/utils/formdata'
import { orderBy, uniqBy } from 'lodash'
import { isPast } from 'date-fns'
import api from '@/api'
import * as analytics from '@/utils/analytics'

import { useProject } from '@/composables/use-project'
import { useFlags } from '@/composables/use-flags'
import { useWorkspace } from '@/composables/use-workspace'
import { useAuth } from '@/composables/use-auth'

const base_url = import.meta.env.VITE_BASE_URL

export default createStore({
    state: {
        issuingStarted: false,
        cartGoods: [],
        dcChecked: false,
        fare: undefined,
        groupPasses: undefined,
        passCode: undefined,
        booking: {
            time: undefined,
            unit: undefined,
            orderSent: false,
        },
        // temp hack for linked reservations before we introduce Order
        second_bookings: [],
        campaign: null,
    },
    getters: {
        backend_uri() {
            return import.meta.env.VITE_BACKEND_URI
        },
        base_url() {
            return base_url
        },
        project() {
            return useProject().value
        },
        flags() {
            return useFlags().value || {}
        },
        workspace() {
            return useWorkspace().value
        },
        user() {
            return useAuth().value
        },
        campaign(state) {
            return state.campaign
        },
        currency(state, { workspace }) {
            return workspace.currency
        },
        authenticated(state, { user }) {
            return !!user
        },
        contact(state, { user }) {
            return user?.contact || {}
        },
        brand_name(state, { workspace }) {
            return workspace.brand_name
        },
        logo(state, { workspace }) {
            return workspace.logo
        },
        has_stripe(state, { workspace }) {
            return workspace?.connect_id
        },
        has_square(state, { workspace }) {
            return workspace?.merchant_id
        },
        is_whitelabel_mode(state, { flags }) {
            return !!flags.with_whitelabel
        },
        ws_business_info(state, { workspace }) {
            return workspace?.business_info
        },
        plans(state, getters, rootState, rootGetters) {
            return orderBy(
                (getters.project?.plans || []).map(plan => {
                    const addToPlan = {
                        available_quantity: plan.quantity
                            ? plan.quantity - plan.plan_passes_count || 0
                            : 999,
                        old_price: plan.amount,
                        amount: plan.amount,
                        tax_amount: plan.tax_amount,
                        price_without_tax: plan.price_without_tax,
                    }

                    if (getters.dynamic_pricing[plan.id]) {
                        addToPlan.old_price = plan.amount
                        const amount = Number(getters.dynamic_pricing[plan.id] || 0)
                        addToPlan.amount = amount
                        addToPlan.tax_amount =
                            amount -
                            Math.round(
                                (amount * rootGetters.tax_percent) /
                                    (1 + rootGetters.tax_percent)
                            )
                        addToPlan.price_without_tax = amount - addToPlan.tax_amount
                        addToPlan.tax_included = true // dynamic pricing is always tax included
                    }

                    return { ...plan, ...addToPlan }
                }),
                ['sales_start', 'available_quantity', 'name'],
                ['asc', 'desc', 'asc']
            )
        },
        plans_by_id(state, { plans }) {
            return plans.reduce((sum, plan) => ({ ...sum, [plan.id]: plan }), {})
        },

        project_type(state, { project }) {
            return project?.type
        },
        project_subtype(state, { project }) {
            return project?.subtype
        },

        issuing_started(state) {
            return state.issuingStarted
        },
        dc_checked(state) {
            return state.dcChecked
        },
        goods(state) {
            return state.cartGoods || []
        },
        fare(state) {
            return state.fare
        },
        pass_code(state) {
            return state.passCode
        },
        full_fare(state, { fare, project }) {
            if (!fare) return

            const { origin, destination } = fare

            return (project.fares || []).find(
                f =>
                    (f.origin_id === origin && f.destination_id === destination) ||
                    (f.origin_id === destination && f.destination_id === origin)
            )
        },
        stations(state, { fare, project }) {
            if (!fare) return

            return {
                origin: project.stations.find(s => s.id === fare.origin),
                destination: project.stations.find(s => s.id === fare.destination),
            }
        },
        booking(state) {
            return state.booking
        },
        second_bookings(state) {
            return state.second_bookings
        },
        booking_unit(state) {
            return state.booking.unit
        },
        booking_time(state) {
            return state.booking.time
        },
        goods_by_id(state, { goods }) {
            return goods.reduce((end, item) => ({ ...end, [item.id]: item }), {})
        },
        total_goods(state, { goods }) {
            return goods.reduce((sum, item) => sum + item.quantity, 0)
        },
        total_amount(state, { goods, plans_by_id }) {
            return goods.reduce((sum, item) => {
                const plan = plans_by_id[item.id]
                return (
                    sum +
                    item.quantity * plan.amount +
                    plan.addons_without_tax +
                    plan.addons_tax
                )
            }, 0)
        },
        tax_percent() {
            return 10 //FIXME:
        },
        tax_amount(state, { total_amount, tax_percent }) {
            return total_amount - Math.round((total_amount * tax_percent) / (1 + tax_percent))
        },
        allow_grouping(state, { project, total_goods, is_lift, goods }) {
            const isGroup = project.with_group_pass && total_goods > 1

            if (!isGroup) return false

            return is_lift ? uniqBy(goods, 'roundtrip').length === 1 : true
        },
        group_passes(state) {
            return state.groupPasses
        },
        is_whole_line(state, getters) {
            return getters.is_lift || getters.project?.pass_layout === 'whole_line'
        },
        is_multiride(state, { project_subtype }) {
            return project_subtype === 'multiride'
        },
        is_multiple_booking(state, { project_type, project }) {
            return project_type === 'booking' && project.with_multiple_reservations
        },
        is_product(state, getters) {
            return getters.project?.is_product
        },
        is_coupon(state, { project_type }) {
            return project_type === 'coupon'
        },
        is_voucher_coupon(state, { is_coupon, project_subtype }) {
            return is_coupon && project_subtype == 'voucher'
        },
        is_loyalty(state, { project_type }) {
            return project_type === 'loyalty'
        },
        is_membership(state, { is_loyalty, project_subtype }) {
            return is_loyalty && project_subtype === 'member'
        },
        is_stampcard(state, { is_loyalty, project_subtype }) {
            return is_loyalty && project_subtype === 'stamp'
        },
        is_voucher(state, getters) {
            return getters.project_type === 'voucher'
        },
        is_ticket(state, getters) {
            return getters.project_type === 'ticket'
        },
        is_event(state, { is_ticket, project_subtype }) {
            return is_ticket && project_subtype === 'event'
        },
        is_admission(state, { is_ticket, project_subtype }) {
            return is_ticket && project_subtype === 'admission'
        },
        is_monetary(state, getters) {
            return getters.project_subtype === 'monetary'
        },
        is_kaisuken(state, getters) {
            return getters.project_subtype === 'kaisuken'
        },
        is_lounge_pass(state, getters) {
            return getters.project_subtype === 'lounge_pass'
        },
        is_transport(state, getters) {
            return getters.project_type === 'transport'
        },
        is_lift(state, { is_transport, project_subtype }) {
            return is_transport && project_subtype === 'lift'
        },
        is_transport_bus(state, getters) {
            return getters.project?.transport_type === 'PKTransitTypeBus'
        },
        is_bundle(state, { project_subtype }) {
            return project_subtype === 'bundle'
        },
        is_booking(state, { project, project_type }) {
            return project_type === 'booking' || project.with_reservation
        },
        is_meeting_pass(state, { project_subtype }) {
            return project_subtype === 'meeting_pass'
        },
        is_commuter(state, { project_subtype }) {
            return project_subtype === 'commuter'
        },
        is_subscription(state, { project_type }) {
            return project_type === 'service'
        },
        with_lottery(state, { project, lottery_drawn, lottery_sales_ended }) {
            return project?.with_lottery && !lottery_drawn && !lottery_sales_ended
        },
        lottery_drawn(state, { project }) {
            return project.lottery?.drawn
        },
        lottery_sales_ended(state, { project }) {
            return project.with_lottery && isPast(project.lottery.end)
        },
        pictogram_src(state, { is_stampcard, is_ticket, is_transport }) {
            if (is_stampcard) return '/images/pictogram-stampcard.png'
            if (is_ticket || is_transport) return '/images/pictogram-ticket.png'

            return '/images/pictogram-coupon.png'
        },
        is_out_of_stock(state, { project }) {
            if (project?.plans?.length) {
                return project.plans.every(
                    plan => plan.quantity && plan.quantity <= plan.plan_passes_count
                )
            }

            if (project?.quantity === undefined || project?.quantity === null) {
                return false
            }

            if (project?.quantity === 0) return true

            const issued = project?.wallet_passes_count || 0

            return !(project?.quantity - issued)
        },
        order_limit(state, { project, is_transport }) {
            return project?.order_limit ? Number(project.order_limit) : is_transport ? 1 : 10
        },
        booking_units(state, { project }) {
            return project?.booking_units || []
        },
        bundle_venues(state, { project, is_bundle }) {
            return (is_bundle && project.bundle_venues) || []
        },
        bundle_venues_with_reservation(state, { bundle_venues }) {
            return bundle_venues.filter(v => v.reservation)
        },
        bundle_units_with_reservation(state, { project, bundle_venues_with_reservation }) {
            return (project.booking_units || []).filter(u =>
                bundle_venues_with_reservation.find(v => v.id === u.venue_id)
            )
        },
        project_venues(state, { project }) {
            return project?.venues || []
        },
        max_reservations(state, { project, bundle_units_with_reservation }) {
            return (
                (project.with_linked_bundle
                    ? bundle_units_with_reservation.length
                    : project.booking?.max_reservations) || 1
            )
        },
        single_pass_project(state, { is_membership, project }) {
            return is_membership || project.with_single_purchase
        },
        is_on_sale(state, { project }) {
            return project?.is_on_sale !== undefined ? project.is_on_sale : true
        },
        with_konbini(state, { is_subscription, flags }) {
            return (flags.with_konbini && !is_subscription) || false
        },
        sales_not_started(state, getters) {
            if (!getters.is_on_sale) return true
            if (!getters.project?.has_sales_limits) return false

            return !getters.project?.allowed_sale_date
        },
        need_quantity(state, { is_monetary, is_voucher }) {
            return is_monetary || is_voucher
        },
        self_checkin_timer(state, { project }) {
            return project?.self_checkin_timer || 3
        },
        is_ended(state, { project, is_event }) {
            if (is_event) {
                return (
                    !!(project?.start_date || project?.expires_at) &&
                    ((project?.expires_at && isPast(project?.expires_at)) ||
                        (project?.start_date && isPast(project?.start_date)))
                )
            }
            return (
                !!(project?.start_date || project?.expires_at) &&
                project?.expires_at &&
                isPast(project?.expires_at)
            )
        },
        sales_start(state, { project }) {
            if (!project?.sales_start || isPast(project?.sales_start)) return

            return project?.sales_start
        },
        dynamic_pricing(state, getters) {
            if (getters.is_whole_line) {
                return getters.project.fares?.[0]?.plans || {}
            } else if (
                getters.full_fare?.plans &&
                Object.keys(getters.full_fare?.plans).length
            ) {
                return getters.full_fare?.plans || {}
            }

            return getters.booking_time?.categories || {}
        },
        with_activation_date(state, { project, is_transport }) {
            return (
                is_transport &&
                !(
                    project.with_reservation ||
                    project.with_next_month_only ||
                    project.start_date
                )
            )
        },
        with_pass_code(state, { project }) {
            return project.with_pass_code
        },
        need_second_bookings(state, { project, booking_time }) {
            return !!(
                project.with_linked_bundle &&
                project.booking_units?.length > 1 &&
                booking_time?.start
            )
        },
        booking_venue(state, { project_venues, booking_unit }) {
            const venueId = booking_unit?.venue_id

            return project_venues.find(v => v.id === venueId)
        },

        has_passes_in_single_pass_project(state, { passes_list, single_pass_project }) {
            return passes_list && single_pass_project && passes_list.length
        },
        need_pass_code(state, { with_pass_code, pass_code }) {
            return with_pass_code && !pass_code
        },
        need_goods(state, { plans, total_goods }) {
            return plans?.length && !total_goods
        },
        need_booking_unit(state, { project, booking_unit, is_booking, is_lounge_pass }) {
            return (
                (project.with_reservation || (is_booking && !is_lounge_pass)) && !booking_unit
            )
        },
        need_booking_time(state, { project, booking_time, is_booking, is_lounge_pass }) {
            return (
                (project.with_reservation || (is_booking && !is_lounge_pass)) && !booking_time
            )
        },
        need_authorization(state, { project, user }) {
            return project.has_registration && !user
        },
        need_data_collection(state, { project, dc_checked, data_collection_complete }) {
            if (project.has_data_collection) {
                // if (getters.project.with_kyc) return true
                if (dc_checked) return false

                return !data_collection_complete
            }

            return false
        },
        need_fare(state, { is_transport, fare }) {
            return is_transport && !fare
        },
        passes_list(state, { user }) {
            const passes = user?.items ? user?.items : []

            if (user?.membership_pass) passes.push(user?.membership_pass)

            return passes
        },
        active_passes(state, { passes_list }) {
            return passes_list?.filter(p => !p.is_expired) || []
        },
        card(state, { user }) {
            return user?.card_last_four
        },
        invoices(state, { user }) {
            return user?.invoices
        },
        payment_sources(state, { user }) {
            return user?.payment_sources
        },
        lottery_tickets(state, { contact }) {
            return contact?.lottery_tickets || []
        },
        last_verification(state, { contact }) {
            if (!contact.last_verification) return

            return contact.last_verification
        },
        is_kyc_done(state, getters) {
            switch (getters.last_verification?.status) {
                case 'approved':
                    return true
                case 'rejected':
                case 'declined':
                case 'pending':
                default:
                    return false
            }
        },
        is_kyc_pending(state, getters) {
            return getters.last_verification?.status === 'pending'
        },
        is_kyc_rejected(state, getters) {
            return getters.last_verification?.status === 'rejected'
        },
        kyc_notes(state, getters) {
            return getters.last_verification?.notes
        },
        has_won_lottery(state, { lottery_tickets }) {
            return lottery_tickets.some(
                ticket => ticket.is_winner && !isPast(ticket.expires_at)
            )
        },
        subscriptions(state, { user }) {
            return user?.subscriptions
        },
        data_collection_complete(state, { project, contact }) {
            const formFields = project.form_fields

            for (const fieldName in formFields) {
                if (fieldName === 'address') {
                    if (!contact?.address) return false

                    const addressesFields = formFields?.[fieldName]?.fields

                    if (addressesFields) {
                        for (const addressFieldName in addressesFields) {
                            const addressField = addressesFields[addressFieldName]
                            if (!addressField?.show || !addressField?.required) continue
                            if (!contact?.address?.[addressFieldName]) return false
                        }
                    } else if (
                        formFields?.['address']?.enabled &&
                        formFields?.['address']?.required &&
                        !contact?.address
                    ) {
                        return false
                    }
                } else {
                    if (!formFields[fieldName].enabled) continue

                    if (!contact?.[fieldName] && !contact?.meta?.[fieldName]) {
                        if (fieldName === 'picture') {
                            if (!contact?.contact_image) return false
                        } else {
                            return false
                        }
                    }
                }
            }

            return true
        },
        can_proceed(
            state,
            { project, sales_not_started, is_out_of_stock, is_ended, single_pass_project }
        ) {
            return (
                project.is_published &&
                !project.is_shorturl_disabled &&
                !project.is_shorturl_expired &&
                !sales_not_started &&
                !is_out_of_stock &&
                !is_ended &&
                !(single_pass_project && project.active_passes_count)
            )
        },

        terms_url(state, { project }) {
            const terms_url = project.link_to_terms
            if (!terms_url) return
            const page = 'terms'

            return terms_url.startsWith(base_url) ? project.link + '/' + page : terms_url
        },
        privacy_url(state, { project }) {
            const privacy_url = project.link_to_privacy
            if (!privacy_url) return
            const page = 'privacy'

            return privacy_url.startsWith(base_url) ? project.link + '/' + page : privacy_url
        },
    },

    mutations: {
        UPDATE_PASS_CODE(state, value) {
            state.passCode = value
        },
        UPDATE_DC_CHECK(state, value) {
            state.dcChecked = value
        },
        UPDATE_CART_GOODS(state, goods) {
            state.cartGoods = goods.value || goods
        },
        UPDATE_CART_FARE(state, fare) {
            state.fare = fare
        },
        UPDATE_CART_BOOKING_UNIT(state, unit) {
            state.booking.unit = unit
        },
        UPDATE_CART_BOOKING_TIME(state, time) {
            state.booking.time = time
        },
        UPDATE_BOOKING_ORDER_SENT(state, value) {
            state.booking.orderSent = value
        },
        UPDATE_CART_GROUP_PASSES(state, value) {
            state.groupPasses = value
        },
        UPDATE_ISSUING_STARTED(state, value) {
            state.issuingStarted = value
        },
        UPDATE_SECOND_BOOKINGS(state, value) {
            state.second_bookings = value
        },
        UPDATE_CAMPAIGN(state, campaign) {
            state.campaign = campaign
        },
    },

    actions: {
        setPassCode({ commit }, code) {
            commit('UPDATE_PASS_CODE', code)
        },
        setCampaign({ commit }, campaign) {
            commit('UPDATE_CAMPAIGN', campaign)
        },
        setGoods({ commit }, goods) {
            commit('UPDATE_CART_GOODS', goods.value || goods)
        },
        setFare({ commit }, fare) {
            commit('UPDATE_CART_FARE', fare)
        },
        setBookingUnit({ commit }, unit) {
            commit('UPDATE_CART_BOOKING_UNIT', unit)
        },
        setBookingTime({ commit }, time) {
            commit('UPDATE_CART_BOOKING_TIME', time)
        },
        setDCCheck({ commit }, value) {
            commit('UPDATE_DC_CHECK', value)
        },
        setCart({ commit }, cart) {
            commit('UPDATE_CART_GOODS', cart?.items || [])
        },
        setGroupPasses({ commit }, value) {
            commit('UPDATE_CART_GROUP_PASSES', value)
        },
        removeGoods({ commit, getters }, id) {
            commit(
                'UPDATE_CART_GOODS',
                getters.goods.filter(cartItem => cartItem.id !== id)
            )
        },
        setBookingOrderSent({ commit }, value) {
            commit('UPDATE_BOOKING_ORDER_SENT', value)
        },
        setSecondBookings({ commit }, value) {
            commit('UPDATE_SECOND_BOOKINGS', value)
        },
        resetCart({ commit }) {
            commit('UPDATE_ISSUING_STARTED', false)
            commit('UPDATE_CART_GOODS', [])
            commit('UPDATE_CART_BOOKING_UNIT', undefined)
            commit('UPDATE_CART_BOOKING_TIME', undefined)
            commit('UPDATE_BOOKING_ORDER_SENT', false)
            commit('UPDATE_SECOND_BOOKINGS', [])
            commit('UPDATE_CART_GROUP_PASSES', undefined)
            commit('UPDATE_CART_FARE', undefined)
            commit('UPDATE_DC_CHECK', false)
        },

        goToSignIn(state, { project }) {
            if (project?.link) {
                return router.visit(`${project.link}/signin`)
            } else {
                return router.visit('/signin')
            }
        },

        async continueIssuingPass({ getters, dispatch, commit }) {
            const { project } = getters
            if (!project) {
                return router.visit('/')
            }
            if (!project.is_published) {
                return router.visit('/')
            }

            if (!getters.issuing_started) commit('UPDATE_ISSUING_STARTED', true)

            if (getters.has_passes_in_single_pass_project) {
                return router.push({
                    url: `${project.link}/passes`,
                    component: 'project/Passes',
                })
            }

            if (getters.need_connection_to_workspace) {
                return router.visit(project.link)
            }

            if (getters.need_pass_code) {
                // return navigate({ name: 'projectCodePage', params: { slug } })
                return router.push({
                    url: `${project.link}/code`,
                    component: 'project/Code',
                })
            }
            if (project.with_kyc && getters.need_authorization) {
                // return navigate({ name: 'projectSignInPage', params: { slug } })
                return router.visit(`${project.link}/signin`)
            }

            if (project.with_kyc && !getters.is_kyc_done) {
                // return navigate({ name: 'projectRegistrationPage', params: { slug } })
                return router.push({
                    url: `${project.link}/registration`,
                    component: 'project/Registration',
                })
            } else if (project.with_kyc && getters.is_kyc_done) {
                commit('UPDATE_DC_CHECK', true)
            }

            if (getters.need_booking_unit) {
                // return navigate({ name: 'projectBookingPage', params: { slug } })
                // return router.visit(`${project.link}/booking`)
                return router.visit(`${project.link}/booking`)
            }

            if (getters.need_booking_time) {
                return router.visit(`${project.link}/availability`)
            }

            if (getters.need_goods && getters.with_activation_date) {
                if (
                    getters.plans?.length === 1 &&
                    getters.order_limit === 1 &&
                    !project.with_seatmap
                ) {
                    dispatch('setGoods', [{ id: getters.plans[0].id, quantity: 1 }])
                } else {
                    return router.visit(`${project.link}/categories`)
                }
            }

            if (getters.need_fare) {
                if (getters.is_whole_line && project.fares?.[0]?.origin_id) {
                    dispatch('setFare', {
                        origin: project.fares[0].origin_id,
                        destination: project.fares[0].destination_id,
                    })
                } else {
                    return router.visit(`${project.link}/fare`)
                }
            }

            if (getters.need_goods) {
                if (
                    getters.plans?.length === 1 &&
                    getters.order_limit === 1 &&
                    !project.with_seatmap
                ) {
                    dispatch('setGoods', [{ id: getters.plans[0].id, quantity: 1 }])
                } else {
                    return router.visit(`${project.link}/categories`)
                }
            }

            if (getters.with_lottery && !getters.has_won_lottery) {
                return router.push({
                    url: `${project.link}/lottery`,
                    component: 'project/Lottery',
                })
            }

            if (getters.need_authorization) {
                // return navigate({ name: 'projectSignInPage', params: { slug } })
                return router.visit(`${project.link}/signin`)
            }

            if (getters.need_data_collection) {
                // return navigate({ name: 'projectRegistrationPage', params: { slug } })
                return router.visit(`${project.link}/registration`)
            }

            if (getters.is_product) {
                return router.visit(`${project.link}/checkout`)
            }

            if (getters.total_goods) {
                return dispatch('makeOrder')
            }

            return router.post(`${getters.project.link}/issue`, {
                campaign: getters.campaign,
                pass_code: getters.pass_code,
            })
        },

        makeOrder(
            { getters },
            { token, meta, coupon, coupon_id, payment_method, ...data } = {}
        ) {
            const orderData = {
                pass_code: getters.pass_code,
                cart: {
                    token,
                    meta,
                    coupon,
                    coupon_id,
                    payment_method,
                    items: getters.goods,
                    fare: getters.fare,
                    group_passes: getters.group_passes,
                    booking: getters.booking?.unit
                        ? {
                              ...getters.booking.time,
                              unit: getters.booking.unit.id,
                          }
                        : null,
                },
                campaign: getters.campaign,
                ...data,
            }

            const headers = []
            const retryToken = sessionStorage?.getItem('retryToken')
            if (retryToken) {
                headers['x-retry-token'] = retryToken
            }

            return router.post(`${getters.project.link}/checkout`, orderData, { headers })

            //TODO: fix and refactor multiple booking process without sending multiple consecutive requests

            // if (!getters.booking?.orderSent) {
            //     result = await dispatch('order', {
            //         project: getters.project.id,
            //         data: orderData,
            //     })
            // }
            //
            // dispatch('setBookingOrderSent', true)
            //
            // if (getters.is_multiple_booking && getters.second_bookings?.length) {
            //     for (const booking of getters.second_bookings) {
            //         if (booking.orderSent) continue
            //
            //         await dispatch('order', {
            //             project: project.id,
            //             data: {
            //                 ...orderData,
            //                 cart: {
            //                     ...orderData.cart,
            //                     booking: { ...booking.time, unit: booking.unit.id },
            //                 },
            //             },
            //         })
            //
            //         booking.orderSent = true
            //     }
            // }
            //
            // return result
        },

        async jwtLogin({ getters }, session) {
            analytics.trackEvent('auth', 'session')
            return router.post(`${getters.project.link}/token`, { session })
        },

        // fix those
        async verifyEmail({ dispatch }, { id, token }) {
            await api.post('/verify-email', { id, token })

            return await dispatch('me')
        },

        async resendConfirmationEmail({ dispatch }, project) {
            await api.post('/resend-email', project ? { project: project.id } : {})

            return await dispatch('me')
        },

        // refactored and checked
        loadAvailability({ getters }, { unitId, from, contact }) {
            return api.post(`/${getters.project.id}/availability`, {
                unit: unitId || null,
                from,
                contact,
            })
        },
        checkIn({ getters }, { pass_id, data }) {
            return api
                .post(`/${getters.project.id}/${pass_id}/self`.replace('//'), data)
                .then(({ code }) => code)
        },
        loadVenue({ getters }, { id }) {
            return api.post(`/${getters.project.id}/scan`, { id }).then(({ venue }) => venue)
        },
        fetchSeatMap({ getters }, { venueId, ...data }) {
            return api.post(`/${getters.project.id}/seatmap/${venueId}`, data)
        },
        fetchSeatPlans({ getters }, { venueId, ...data }) {
            return api.post(`/${getters.project.id}/seatplans/${venueId}`, data)
        },
        getProjectVenues({ getters }, { pagination = {} }) {
            return api.post(`/${getters.project.id}/venues`, { ...pagination })
        },
        updateLanguage(state, language) {
            return api.patch('/language', { language })
        },
        createIntent({ getters }, { cart, payment_method }) {
            return api
                .post(`/${getters.project.id}/intent`, { cart, payment_method })
                .then(({ data }) => data)
        },
    },
})
